<template>
    <div></div>
</template>

<script>
// import axios from 'axios'
// import { Messages } from '@/utils/constants';
export default {
    // server redirects to this after successful checkout

    mounted() {
        this.saveSession()
    },

    methods: {
        async saveSession() { 
            // try {
            //     let queryParam = this.queryParam = this.$route.query.session_id

            //     let payload = {
            //         session_id: queryParam,
            //         user_id: this.$session.get('user').id
            //     }

            //     if (queryParam === undefined || queryParam === null) {
            //         this.$router.push('/404')
            //     }
                
            //     await axios.post(process.env.VUE_APP_API_URL +'/membership/save-session-id', payload, { headers: { "Content-Type": "application/json" } })
            //     this.refreshUserToken()
            // } catch (error) {
            //     console.log(error)
            // }
        },
        async refreshUserToken() {
            // await axios
            //     .get(process.env.VUE_APP_API_URL + '/refreshToken', {
            //         'Authorization': 'Bearer ' + this.$session.get('refresh_token')
            //     })
            //     .then((response) => {
            //         // remove vue session
            //         this.$session.destroy()

            //         // start new vue session
            //         this.$session.start()

            //         // save the new token
            //         this.$session.set('access_token', response.data.access_token)
            //         this.$session.set('refresh_token', response.data.refresh_token)
            //         this.$session.set('user', response.data.user)

            //         // redirect to dashboard
            //         this.$router.push("/");
            //     })
            //     .catch((error) => {
            //         if(error.response.data.message === undefined) {
            //             this.$store.dispatch('showSnackBar', {status: true, message: 'Something went wrong', type: Messages.SUCCESS})
            //         } else {
            //             this.$store.dispatch('showSnackBar', {status: true, message: error.response.data.message, type: Messages.ERROR})
            //         }
            //     })
        }
    }
}
</script>

<style>

</style>